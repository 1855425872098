import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LinkDocumentTable, LinkLocationTable } from '../../models/link-document-table.model';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LinkTableService {

  private _specialDocuments: LinkDocumentTable[] = [];

constructor(
  private firestore: AngularFirestore
  ) { }

  public readonly documentCollectionName = 'link-document-tables';
  public readonly locationCollectionName = 'link-location-tables';

  async saveData(data: LinkDocumentTable | LinkLocationTable, collection: string) {
    data.uid = this.firestore.createId();
    return this.firestore.collection(collection).doc(data.uid).set(data);
  }

  getData(collection: string) {
    return this.firestore.collection<LinkDocumentTable | LinkLocationTable>(collection).valueChanges();
  }

  removeItem(uid: string, collection: string): Promise<void> {
    return this.firestore.collection(collection).doc(uid).delete();
  }

  updateData(data: LinkDocumentTable | LinkLocationTable, collection: string): Promise<void> {
    return this.firestore.collection(collection).doc(data.uid).update(data);
  }

  getTableByUserIbgeCode(ibgeCode: string) {
    return this.getData(this.locationCollectionName)
    .pipe(
      take(1),
      map((tables: any) => {
        tables = tables as LinkLocationTable[];
        return tables.find((table: LinkLocationTable) => {
          let filtered = table.cities.filter((city) => { 
            if (city.code.toString() == ibgeCode.toString()) {
              return true
            } else {
              return false
            } 
           });
          return filtered.length > 0;
        });
      })
    );
  }

  set setSpecialDocuments(data: LinkDocumentTable[]) {
    this._specialDocuments = data;
  }

  get specialDocuments() {
    return this._specialDocuments;
  }

}
